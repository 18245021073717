/* eslint-disable class-methods-use-this */
import Service from '../Service';

class LeadService extends Service {
  constructor() {
    super('/leads');
  }

  download() {
    return`${this.baseUrl}`;
  }

}

const leadService = new LeadService();
export default leadService;
