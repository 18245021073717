/* eslint-disable class-methods-use-this */
import Service from '../Service';
import http from "@/http";

class HighlightService extends Service {
    constructor() {
        super('/highlights');
    }

    getTotal(params) {
        return http.get(`${this.baseUrl}/total`, { params });
    }

    getQualityRegistry(params) {
        return http.get(`${this.baseUrl}/ensure-quality`, { params });
    }

    getQualityRegistryToExcel() {
        return `${this.baseUrl}/ensure-quality/to-excel`;
    }

    getClients(params) {
        return http.get(`${this.baseUrl}/clients/${params}`);
    }

    getFieldsTable() {
        return [
            {
                key: 'subsee_reference',
                label: 'Código',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'advertiser',
                label: 'Anunciante',
                sortable: true,
            },
            {
                key: 'highlight_type',
                label: 'Tipo',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'business_type',
                label: 'Negócio',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'internal_code',
                label: 'Referência do Cliente',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'reference',
                label: 'Referência',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'total_photos',
                label: 'Total Fotos',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'has_map',
                label: 'Localização',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
        ];
    }

    getFieldsTableSynthetic() {
        return [
            {
                key: 'subsee_reference',
                label: 'Código',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'advertiser',
                label: 'Anunciante',
                sortable: true,
            },
            {
                key: 'highlight_type',
                label: 'Tipo',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'business_type',
                label: 'Negócio',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'contract',
                label: 'Total Contratado',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'total',
                label: 'Total utilizado',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
        ];
    }

    getFieldsTableEnsureQuality() {
        return [
            {
                key: 'subsee_reference',
                label: 'Código',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'advertiser',
                label: 'Anunciante',
                sortable: true,
            },
            {
                key: 'reference',
                label: 'Referência',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'internal_code',
                label: 'Ref. Anunciante',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'business',
                label: 'Negócio',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'dorms',
                label: 'Dormitórios',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'areas',
                label: 'Áreas',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'condo_details',
                label: 'Det. Condomínio',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'description',
                label: 'Descrição',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'position',
                label: 'Mapa',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'photo',
                label: 'Fotos',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
        ];
    }
}

const highlightService = new HighlightService();
export default highlightService;
