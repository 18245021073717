<template>
  <div>
    <loading :enable="loading" />
    <b-row class="card mb-5">
      <h5 class="title-card"><i class="fas fa-filter"></i> Filtros</h5>
      <b-row class="px-3">
        <b-col lg="3">
          <b-form-group label="Cliente">
            <vue-bootstrap-typeahead
                :data="clients"
                v-model="clientSearch"
                :serializer="s => s.name"
                placeholder="Digite o nome ou código do cliente"
                @hit="filter.advertiser = $event.value"
                :minMatchingChars="1"
                :inputClass="clientSearch && !filter.advertiser ? 'is-invalid' : ''"
            />

            <span
                class="invalid-feedback d-block"
                v-if="clientSearch && !filter.advertiser"
            >
              Selecione corretamente o cliente
            </span>
          </b-form-group>
        </b-col>

        <b-col lg="3">
          <b-form-group label="Tipo de anúncio">
            <b-form-select v-model="filter.type">
              <b-form-select-option :value="null">Todos</b-form-select-option>
              <b-form-select-option value="highlights">Apenas destaques</b-form-select-option>
              <b-form-select-option value="super_highlights">Apenas Super Destaques</b-form-select-option>
              <b-form-select-option value="both">Super destaques ou destaques</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="3">
          <b-form-group label="Tipo de Negócio">
            <b-form-select v-model="filter.business">
              <b-form-select-option :value="null">Todos</b-form-select-option>
              <b-form-select-option value="289fbbf4-6fd3-47db-85fe-e72772efd6c0">Venda</b-form-select-option>
              <b-form-select-option value="9e867846-fb2b-491e-954d-bbe68a1b88eb">Locação</b-form-select-option>
              <b-form-select-option value="5a60f718-8061-485a-ba07-29ed192ace67">Temporada</b-form-select-option>
              <b-form-select-option value="10c93bd8-e280-4510-be5c-18ef024cd267">Lançamento</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="p-3">
        <b-col>
          <h5 style="font-size: 1rem">Possíveis inconsistências:</h5>

          <div class="d-flex justify-content-between">
            <b-form-checkbox v-model="filter.dorms">
              Quantidade de dormitórios
            </b-form-checkbox>

            <b-form-checkbox v-model="filter.area">
              Áreas privativa e total
            </b-form-checkbox>

            <b-form-checkbox v-model="filter.description">
              Descrição nula
            </b-form-checkbox>

            <b-form-checkbox v-model="filter.condo_details">
              Detalhes do condomínio
            </b-form-checkbox>

            <b-form-checkbox v-model="filter.position">
              Mapa (Localização)
            </b-form-checkbox>

            <b-form-checkbox v-model="filter.photo">
              Sem foto
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>

      <b-col lg="12" class="py-3">
        <b-button @click="handleFilter" variant="primary" class="mr-2" :disabled="loading">
          <b-spinner small v-if="loading"></b-spinner>
          <i class="fas fa-search" v-if="!loading"></i> Pesquisar
        </b-button>
      </b-col>
    </b-row>

    <b-row class="card mb-5">
      <div class="w-100 p-3">
        <h5 v-if="total">Total de registros: {{total}}</h5>
        <a href="#" v-if="filter.advertiser && items.length" @click="download">Exportar</a>
        <a href="#" class="ml-4" v-if="filter.advertiser" v-b-modal.statsModal>Estatísticas</a>
        <b-table
            responsive
            class="mt-3"
            :items="items"
            :fields="fields"
            striped
            bordered
            hover
            show-empty
        >
          <template v-slot:empty>
            <h4 class="text-center">Nenhum Resultado encontrado</h4>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>

          <template v-slot:cell(reference)="data">
            <a :href="`https://sub100.com.br/imoveis/${data.item.reference}`" target="_blank">{{data.item.reference}}</a>
          </template>

          <template v-slot:cell(dorms)="data">
            <b-icon icon="check-circle-fill" v-if="data.item.dorms" variant="success"/>
            <b-icon icon="x-circle-fill" v-else variant="danger"/>
          </template>

          <template v-slot:cell(areas)="data">
            <b-icon icon="check-circle-fill" v-if="data.item.areas" variant="success"/>
            <b-icon icon="x-circle-fill" v-else variant="danger"/>
          </template>

          <template v-slot:cell(description)="data">
            <b-icon icon="check-circle-fill" v-if="data.item.description" variant="success"/>
            <b-icon icon="x-circle-fill" v-else variant="danger"/>
          </template>

          <template v-slot:cell(condo_details)="data">
            <b-icon icon="check-circle-fill" v-if="data.item.condo_details" variant="success"/>
            <b-icon icon="x-circle-fill" v-else variant="danger"/>
          </template>

          <template v-slot:cell(photo)="data">
            <b-icon icon="check-circle-fill" v-if="data.item.photo" variant="success"/>
            <b-icon icon="x-circle-fill" v-else variant="danger"/>
          </template>

          <template v-slot:cell(position)="data">
            <b-icon icon="check-circle-fill" v-if="data.item.position" variant="success"/>
            <b-icon icon="x-circle-fill" v-else variant="danger"/>
          </template>
        </b-table>

        <a href="#" @click="loadMore" v-if="items.length && filter.page !== lastPage">Carregar mais</a>
      </div>
    </b-row>

    <b-modal id="statsModal" title="Download de estatísticas" :cancel-title="'Cancelar'" :ok-title="'Download'" @ok="downloadStats">

      <b-form-group label="Início">
        <b-form-datepicker
            v-model="statsFilter.start_date"
            :locale="'pt-br'"
            class="mb-2"
        ></b-form-datepicker>
      </b-form-group>

      <b-form-group label="Fim">
        <b-form-datepicker
            v-model="statsFilter.end_date"
            :locale="'pt-br'"
            class="mb-2"
        ></b-form-datepicker>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import HighlightService from "@/services/highlights/HighlightService";
import _ from "underscore";
import Loading from "@/components/shared/loading";
import highlightService from "@/services/highlights/HighlightService";
import leadService from "@/services/contract/LeadService";
import querystring from 'querystring';
export default {
  components: {Loading},
  data() {
    return {
      loading: false,
      lastPage: null,
      total: null,
      fields: HighlightService.getFieldsTableEnsureQuality(),
      clients: [],
      clientSearch: '',
      clientCities: [],
      clientCitySearch: '',
      propertyCities: [],
      propertyCitySearch: '',
      items: [],
      filter: {
        type: null,
        dorms: true,
        area: true,
        condo_details: true,
        description: true,
        position: true,
        photo: true,
        advertiser: null,
        page: 1,
        business: null
      },
      statsFilter: {
        start_date: '',
        end_date: ''
      }
    }
  },
  async mounted() {
    if(localStorage.getItem('importer-auth') !== 'bf:P5z#4n0}2kH[y') {
      this.$router.push({name: 'login'});
    }
  },
  methods: {
    async getAll(filter) {
      this.loading = true;
      await HighlightService.getQualityRegistry(filter).then((response) => {
        this.total = response.data.total;
        this.lastPage = response.data.last_page;

        response.data.data.map((item) => {
          const line = {
            subsee_reference: item.subsee_reference,
            advertiser: item.advertiser,
            reference: item.reference,
            dorms: item.dorms === 'OK',
            areas: item.area === 'OK',
            description: item.description === 'OK',
            condo_details: item.condo_details === 'OK',
            photo: item.photo === 'OK',
            position: item.position === 'OK',
            internal_code: item.internal_code,
            business: item.business
          }

          this.items.push(line);
        });

      });
      this.loading = false;
    },
    async loadClients(query) {
      await HighlightService.getClients(query).then((response) => {
        const {data} = response;
        this.clients = data.map((item) => {
          return {
            value: item.id,
            name: item.subsee_reference + ' - ' + item.name
          }
        })
      });
      this.loading = false;
    },
    handleClearFilter() {
      this.filter = {
        type: null,
        dorms: true,
        area: true,
        condo_details: true,
        description: true,
        position: true,
        photo: true,
        advertiser: null,
        page: 1,
        business: null
      };
      this.getAll();
    },
    handleFilter() {
      this.filter.page = 1;
      this.items = [];

      if(!this.filter.advertiser) {
        this.clientSearch = ' '
        return;
      }
      this.getAll(this.filter);
    },
    loadMore() {
      this.filter.page ++;
      this.getAll(this.filter);
    },
    async download() {
      const filter = {
        dorms: this.filter.dorms,
        area: this.filter.area,
        condo_details: this.filter.condo_details,
        description: this.filter.description,
        position: this.filter.position,
        photo: this.filter.photo,
        advertiser: this.filter.advertiser,
        page: this.filter.page,
      }

      if(this.filter.type) {
        filter.type = this.filter.type
      }

      if(this.filter.business) {
        filter.business = this.filter.business;
      }

      const params  = querystring.stringify(filter);
      const url = process.env.VUE_APP_IMPORTER_URL + highlightService.getQualityRegistryToExcel() + '?' + params;
      window.open(url);
    },
    async downloadStats(bvModalEvt) {
      bvModalEvt.preventDefault();

      const filter = {
        start_date : this.statsFilter.start_date,
        end_date: this.statsFilter.end_date,
        client: this.filter.advertiser
      };
      const params  = querystring.stringify(filter);
      const url = process.env.VUE_APP_IMPORTER_URL + leadService.download() + '?' + params;
      window.open(url);
      this.$bvModal.hide('statsModal');
    }
  },
  watch: {
    clientSearch: _.debounce(function(addr) {
      if(!addr) {
        this.filter.advertiser = null;
      }
      this.loadClients(addr)
    }, 500)
  }
}
</script>